import * as React from "react";

const CardIcon = ({
  style = { width: "20px", margin: "0 24px" },
  width = "20px",
  height = "16px",
  stroke = "#FFF",
  className = "",
  viewBox = "0 0 20 16",
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    className={`svg-icon ${className || ""}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={style.stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M4.252 11.052h3.75M14.252 11.052h1.25M19.252 6.052H.502M18.002 14.802H1.752c-.69 0-1.25-.56-1.25-1.25V2.302c0-.69.56-1.25 1.25-1.25h16.25c.69 0 1.25.56 1.25 1.25v11.25c0 .69-.56 1.25-1.25 1.25z" />
    </g>
  </svg>
);

export default CardIcon;
